import type { CSSProperties } from "react";

import { AddBusiness } from "@mui/icons-material";
import {
  IconButton,
  Button,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Link from "next/link";
import { useTranslation } from "react-i18next";

type Props = {
  path: string;
  buttonStyle?: CSSProperties;
};

export const NewAssetButton = ({ path, buttonStyle }: Props) => {
  const { t } = useTranslation();

  const { breakpoints } = useTheme();
  const isScreenSmallerThanMdSize = useMediaQuery(breakpoints.down("md"));

  return (
    <Link href={path} passHref style={{ textDecoration: "none" }}>
      {isScreenSmallerThanMdSize ? (
        <Tooltip title={t("newAsset")}>
          <IconButton sx={{ border: "1px solid #3d3d3c" }}>
            <AddBusiness />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          data-test="create-asset-btn"
          disableElevation
          sx={{ ...buttonStyle }}
        >
          {t("newAsset")}
        </Button>
      )}
    </Link>
  );
};
